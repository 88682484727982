/**
 * --------------------------------------------------------------------------
 * Lennox Cirrus (v4.5.3): custom collapse.js
 * --------------------------------------------------------------------------
 */

import $ from 'jquery'

class CustomCollapse {
  constructor() {
    console.log("loaded collapse");
  }
}

$(document).on('click', '.accordion-icons-btn', function () {
  // Set default variables
  const thisEl = $(this)
  const thisARIA = 'aria-expanded'
  const iconOpen = 'fal fa-minus'
  const iconClosed = 'fal fa-plus'
  var sameTabOpen = false

  // Check if this a single or multiple accordion
  let parentContainer = thisEl.closest('.accordion-container')

  if(parentContainer.attr("data-type") === "single") {
    parentContainer.find(".accordion-icons-btn").each(function() {
      if(thisEl[0] === $(this)[0]) {
        if($(this).attr(thisARIA) === "true") {
          sameTabOpen = true;
        }
      }

      $(this).attr(thisARIA, 'false')
      $(this).find('i').attr('class', iconClosed);
      $(this).parent().find('.accordion-icons-btn-expand').slideUp(200);
    })
  }

  if(sameTabOpen === false) {
    // Change the icons based on what is open or closed
    if (thisEl.attr(thisARIA) === 'false') {
      thisEl.attr(thisARIA, 'true')
      thisEl.find('i').attr('class', iconOpen)
    } else {
      thisEl.attr(thisARIA, 'false')
      thisEl.find('i').attr('class', iconClosed)
    }

    thisEl.parent().find('.accordion-icons-btn-expand').slideToggle(200)
  }
})

export default CustomCollapse

/**
 * --------------------------------------------------------------------------
 * Lennox Cirrus (v4.5.3): custom calling tooltips and popovers.js
 * --------------------------------------------------------------------------
 */

import $ from 'jquery'

class CustomTooltipsPopovers {
  constructor() {
    //console.log("loaded tooltips and popovers");
  }
}

$(function () {
  $('[data-toggle="popover"]').popover()
});

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
});

export default CustomTooltipsPopovers

/**
 * --------------------------------------------------------------------------
 * Lennox Cirrus (v4.5.3): custom truncated breadcrumb layout
 * --------------------------------------------------------------------------
 */

import $ from 'jquery'

class CustomBreadcrumbsTruncated {
  constructor() {
    //console.log("loaded truncated breadcrumbs");
  }
}

var allBreadcrumbs = document.querySelectorAll("nav[aria-label='breadcrumb'] ol");
for(var i = 0; i < allBreadcrumbs.length; i++) {
  var breadcrumbs = allBreadcrumbs[i];

  var breadcrumbItems = breadcrumbs.querySelectorAll(".breadcrumb-item");
  var truncateLength = 5;

  if(breadcrumbs.getAttribute("data-truncate") !== null) {
    truncateLength = parseInt(breadcrumbs.getAttribute("data-truncate"));
  }

  if(breadcrumbItems.length > 5) {
    breadcrumbItems.forEach(function(crumb, loopIndex) {
      if(loopIndex === 0) {return false;}
      if(loopIndex === breadcrumbItems.length - 1) {return false;}
      if(loopIndex === breadcrumbItems.length - 2) {return false;}
      if(loopIndex === breadcrumbItems.length - 3) {
        crumb.classList.add("breadcrumb-item-truncated");
        return false;
      }

      crumb.classList.add("sr-only");
    });

    var truncatedHTML = document.createElement("li");
    truncatedHTML.className = "breadcrumb-item";
    truncatedHTML.innerText = "...";
    breadcrumbs.insertBefore(truncatedHTML, breadcrumbItems[breadcrumbItems.length - 3]);

    //var firstCrumb = breadcrumbItems[0].outerHTML;
    //var secondToLastCrumb = breadcrumbItems[breadcrumbItems.length - 3];
    //secondToLastCrumb.classList.add("breadcrumb-item-truncated");
    //secondToLastCrumb = secondToLastCrumb.outerHTML;
    //var lastCrumb = breadcrumbItems[breadcrumbItems.length - 2].outerHTML;
    //var currentCrumb = breadcrumbItems[breadcrumbItems.length - 1].outerHTML;

    //var truncatedHTML = "<li class='breadcrumb-item'>...</li>";

    //var newBreadcrumbHTML = firstCrumb + truncatedHTML + secondToLastCrumb + lastCrumb + currentCrumb;

    //breadcrumbs.innerHTML = newBreadcrumbHTML;
  }
}

export default CustomBreadcrumbsTruncated
